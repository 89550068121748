import {Divider} from "antd";
import {ReactElement } from "react";
import {Group} from "./navigation";
import {TruckPart} from "./parts";
import Tile from "./Tile";
import {hasRequired} from "./utils";

interface Props {
  group:Group
  configuration:TruckPart[]
  onSelectPart:(g:Group, p:TruckPart)=>void
}
const GroupView = (props:Props):ReactElement => {
  const {group:g, configuration, onSelectPart} = props;


  const isSelected = (part:TruckPart) => {
    return ( configuration.find( sp => sp.id === part.id ) )
  }

  const handleSelectPart = (group:Group, part:TruckPart) => {
    onSelectPart( group, part );
  }

  return <>
    <Divider orientation="left">{g.title}</Divider>

    <div style={{display: "flex", gap: "20px", flexWrap: "wrap"}}>
      {g.parts
        ?.filter( (p: any) => hasRequired( p, configuration ) )
        .map( (p, ndx0) => {
          const src = p.src;
          const title = p.title;
          const classNames = [
            'tile', 'clicker',
          ];
          if ( isSelected(p) ) { 
            classNames.push( 'selected' )
          }
          else {
            classNames.push( 'selectable' )
          };

          return (<div
            key={`part-${ndx0}`}
            className={classNames.join( ' ' )}
            onClick={() => handleSelectPart(g,p)}
          >
            <Tile 
              key={`tile-${ndx0}`}
              src={src} title={title} />
          </div>)

        })}
    </div>
  </>

}

export default GroupView;


