import {Image} from "antd";

const Tile = ( props:any ) => {
  const { title, description, src } = props;

  const tileTextStyle:Record<string, string> = {
    textAlign: "center",
    fontWeight: "600",
  }


  return <>
    <div style={{ display: "flex", padding: "6px", paddingBottom: "2px", width: "164px" }} >
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center",}} >
      <Image 
        src={src} 
        preview={false}
        style={{borderRadius: "12px"}}
        width={150}
      />
      {title && (
        <div style={tileTextStyle}>{title}</div>
      )}
    </div>
    {description && (
      <div style={{width: "70%", marginLeft: "12px", textAlign: "left", marginTop: "20px" }}>
        {description}
      </div>
    )}
  </div>
  </>

};

export default Tile;



