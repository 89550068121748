import _ from "lodash";
import {Group, Step} from "./navigation";
import {TruckPart} from "./parts";

export interface TaggedImage {
  tags:string[], 
  src:string 
}

export function getImgForTags( imgLst:TaggedImage[], tags:string[] | undefined ) : string | undefined {
  if ( !tags ) return;

  //find exact match
  const exact = imgLst.find( img => _.isEqual( [ ...tags ].sort(), [ ...(img.tags) ].sort() ) );
  if ( exact ) {
    return exact.src;
  }

  const scoreLst = imgLst.map( img => {
    const tagIntersection = _.intersection( img.tags, tags )
    const score = tagIntersection.length;
    return {
      score,
      img,
    };
  })
  .sort( (a,b ) => {
    const rslt = b.score - a.score
    if ( rslt === 0 ) { //if score is the same, get least specified
      return a.img.tags.length - b.img.tags.length;
    }
    return rslt;
  });

  const scored = scoreLst.shift();
  if ( scored?.score === 0 ) return; //if none found, return undefined

  return scored?.img.src;

}

//does part have it's required part in partList?
export function hasRequired(part:TruckPart, partLst:TruckPart[] | undefined):boolean {

  //if no required parts, return true
  if ( !part.requiredIds?.length ) return true;

  const lst = _.intersection( part.requiredIds, partLst?.map( p => p.id ) );
  return lst.length > 0;
}

//does this group have any parts after filtering out parts that don't
//have their required part in partList?
export function groupHasParts(group:Group, partList:TruckPart[] | undefined):boolean {
  const parts = group.parts?.filter( p => hasRequired( p, partList ) );
  return !!parts?.length;
}

//does this step have any gropus after filtering out gropus that don't
//have any parts after filtering for their required part in partList?
export function stepHasParts(step:Step, partList:TruckPart[] | undefined):boolean {
  const groups = step.groups.filter( g => groupHasParts( g, partList ) );
  return !!groups?.length;
}

export function togglePart( part:TruckPart | undefined, groupList:TruckPart[] | undefined, selectedLst:TruckPart[] | undefined ) : TruckPart[] {

  //filter out parts from group
  const deselected = _.difference( selectedLst, groupList || [] );

  //add selected part
  const selected = ( part ) 
    ? deselected.concat( part ) 
    : deselected;

    return selected;
}

export function fillDefaults( groups:Group[], parts:TruckPart[] | undefined ) :TruckPart[] {

  //add default part selections when nothing is selected from a group
  const rslt = groups.reduce( (acc:TruckPart[], g:Group ) => {

    //this group has already been selected
    if ( _.intersection( acc, g.parts ).length ) return acc;

    const lst = g.parts?.filter( p => hasRequired( p, acc ) );
    if ( !!lst?.length ) acc.push( lst[0] );

    return acc;

  }, [...(parts || [] )] );


  return rslt;
}


