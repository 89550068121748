
export interface TruckPart {
  id: string
  title: string
  tags: string[]
  description?: string | undefined
  src?: string
  requiredIds?: string[]
}

export function getTruckParts() : TruckPart[] {
return [
    {
        "id": "truckPart-1",
        "title": "LET",
        "tags": [
            "let"
        ]
    },
    {
        "id": "truckPart-2",
        "title": "LNT",
        "tags": [
            "lnt"
        ]
    },
    {
        "id": "truckPart-3",
        "title": "Electric",
        "tags": [
            "ev"
        ]
    },
    {
        "id": "truckPart-4",
        "title": "Diesel",
        "tags": [
            "diesel"
        ]
    },
    {
        "id": "truckPart-5",
        "title": "CNG",
        "tags": [
            "cng"
        ],
        requiredIds: [
          "truckPart-1"
        ],
    },
    {
        "id": "truckPart-6",
        "title": "Delivery",
        "tags": [
            "delivery"
        ]
    },
    {
        "id": "truckPart-7",
        "title": "Utility",
        "tags": [
            "utility"
        ]
    },
    {
        "id": "truckPart-8",
        "title": "Refuse",
        "tags": [
            "refuse"
        ]
    },
    {
        "id": "truckPart-9",
        "title": "Construction",
        "tags": [
            "construction"
        ]
    },
    {
        "id": "truckPart-10",
        "title": "Other",
        "tags": [
            "other"
        ]
    },
    {
        "id": "truckPart-11",
        "title": "Dry Van Box",
        "tags": [
            "dry"
        ]
    },
    {
        "id": "truckPart-12",
        "title": "Reefer Box",
        "tags": [
            "reefer"
        ]
    },
    {
        "id": "truckPart-13",
        "title": "Beverage Box",
        "tags": [
            "bev"
        ]
    },
    {
        "id": "truckPart-14",
        "title": "Flatbed",
        "tags": [
            "fb"
        ]
    },
    {
        "id": "truckPart-15",
        "title": "Straight Truck",
        "tags": [
            "straight"
        ]
    },
    {
        "id": "truckPart-16",
        "title": "Rolloff",
        "tags": [
            "rolloff"
        ]
    },
    {
        "id": "truckPart-17",
        "title": "Dump Body",
        "tags": [
            "dump"
        ]
    },
    {
        "id": "truckPart-18",
        "title": "Front Loader",
        "tags": [
            "fl"
        ]
    },
    {
        "id": "truckPart-19",
        "title": "Rear Loader",
        "tags": [
            "rl"
        ]
    },
    /*
    {
        "id": "truckPart-20",
        "title": "Automated Side Loader",
        "tags": [
            "asl"
        ]
    },
   */
    {
        "id": "truckPart-21",
        "title": "Standard White",
        "tags": [
            "white"
        ]
    },
    {
        "id": "truckPart-22",
        "title": "Fire Engine Red",
        "tags": [
            "red"
        ]
    },
    /*
    {
        "id": "truckPart-23",
        "title": "Bright Yellow",
        "tags": [
            "yellow"
        ]
    },
    {
        "id": "truckPart-24",
        "title": "Black",
        "tags": [
            "blackl"
        ]
    },
    {
        "id": "truckPart-25",
        "title": "Lime Green",
        "tags": [
            "green"
        ]
    },
   */
    {
        "id": "truckPart-26",
        "title": "Navy Blue",
        "tags": [
            "blue"
        ]
    },
    {
        "id": "truckPart-27",
        "title": "Solid",
        "tags": [
            "sdoor",
        ]
    },
    {
        "id": "truckPart-30",
        "title": "Bi-Fold",
        "tags": [
            "bifold",
        ]
    },
    {
        "id": "truckPart-33",
        "title": "Flip-Up / Fold Back",
        "tags": [
            "foldup",
        ]
    },
    {
        "id": "truckPart-36",
        "title": "16\" x 7\" S/S West Coast Htd \"B\"",
        "tags": [
            "wchmirror"
        ],
        requiredIds: [
          "truckPart-27"
        ],
    },
    {
        "id": "truckPart-37",
        "title": "19\" x 8\" Black 4-Way Split Lens Htd w/ Signal \"D\"",
        "tags": [
            "4waymirror"
        ],
        requiredIds: [
          "truckPart-27"
        ],
    },
    {
        "id": "truckPart-38",
        "title": "16\" x 7\" S/S West Coast \"A\"",
        "tags": [
            "wcmirror"
        ],
        requiredIds: [
          "truckPart-27"
        ],
    },
    {
        "id": "truckPart-39",
        "title": "4 Way",
        "tags": [
            "4-way"
        ],
        requiredIds: [
          "truckPart-30",
          "truckPart-33"
        ],
        
    },
    {
        "id": "truckPart-40",
        "title": "LH Drive",
        "tags": [
            "lh"
        ]
    },
    {
        "id": "truckPart-41",
        "title": "RH Drive",
        "tags": [
            "rh"
        ]
    },
    {
        "id": "truckPart-42",
        "title": "Dual Drive",
        "tags": [
            "dd"
        ]
    },
    {
        "id": "truckPart-43l",
        "title": "2-way adjustable",
        "tags": [
            "2way-left"
        ]
    },
    {
        "id": "truckPart-43r",
        "title": "2-way adjustable",
        "tags": [
            "2way-right"
        ]
    },
    /*
    {
        "id": "truckPart-44l",
        "title": "Sears Air Ride",
        "tags": [
            "sears-left"
        ]
    },
    {
        "id": "truckPart-44r",
        "title": "Sears Air Ride",
        "tags": [
            "sears-right"
        ]
    },
   */
    {
        "id": "truckPart-45l",
        "title": "Sears Sentry Air Ride",
        "tags": [
            "sentry-left"
        ]
    },
    {
        "id": "truckPart-45r",
        "title": "Sears Sentry Air Ride",
        "tags": [
            "sentry-right"
        ]
    },
    {
        "id": "truckPart-46l",
        "title": "Flip-up / Fold-down",
        "tags": [
            "flipd-left"
        ]
    },
    {
        "id": "truckPart-46r",
        "title": "Flip-up / Fold-down",
        "tags": [
            "flipd-right"
        ]
    },
    {
        "id": "truckPart-47",
        "title": "Asphalt Gray Cloth",
        "tags": [
            "asphalt_gray"
        ]
    },
    {
        "id": "truckPart-48",
        "title": "Black Vinyl",
        "tags": [
            "vinyl"
        ]
    },
    {
        "id": "truckPart-49",
        "title": "BorgWarner",
        "tags": [
            "borgwarner"
        ],
        requiredIds: [
          "truckPart-3"
        ],
    },
    {
        "id": "truckPart-50",
        "title": "240 kwh",
        "tags": [
            "ev240"
        ],
        requiredIds: [
          "truckPart-3"
        ],
    },
    {
        "id": "truckPart-51",
        "title": "400 kwh",
        "tags": [
            "ev400"
        ],
        requiredIds: [
          "truckPart-3"
        ],
    },
    {
        "id": "truckPart-52",
        "title": "L9",
        "tags": [
            "L9"
        ],
        requiredIds: [
          "truckPart-4",
          "truckPart-5",
        ],
    },
    {
        "id": "truckPart-53",
        "title": "X12",
        "tags": [
            "X12"
        ],
        requiredIds: [
          "truckPart-4",
          "truckPart-5",
        ],
    },
]
}

export interface PartImage {
  tags: string[]
  src: string
}

export function getPartImages():PartImage[] {
return [
{ tags:["let"]                        , src: '/Thumbnails//Models/model_let.png' }             , 
{ tags:["lnt"]                        , src: '/Thumbnails//Models/model_lnt.png' }             , 
{ tags:["cng"]                        , src: '/Thumbnails//Fuel/CNG.png' }                     , 
{ tags:["ev"]                         , src: '/Thumbnails//Fuel/Electric.png' }                , 
{ tags:["diesel"]                     , src: '/Thumbnails//Fuel/Diesel.png' }                  , 
{ tags:["borgwarner"]                 , src: '/Thumbnails//PowerTrain/BorgWarner.png' }        , 
{ tags:["ev400"]                      , src: '/Thumbnails//PowerTrain/400kWh.png' }            , 
{ tags:["ev240"]                      , src: '/Thumbnails//PowerTrain/240kWh.png' }            , 
{ tags:["L9"]                      , src: '/Thumbnails//PowerTrain/l9_thumb.png' }            , 
{ tags:["X12"]                      , src: '/Thumbnails//PowerTrain/x12_thumb.png' }            , 
{ tags:["4waymirror"]                 , src: '/Thumbnails//Mirrors/4WAYmirror.png' }           , 
{ tags:["4-way"]                      , src: '/Thumbnails//Mirrors/4-way.png' }                , 
{ tags:["wchmirror"]                  , src: '/Thumbnails//Mirrors/WCHmirror.png' }            , 
{ tags:["wcmirror"]                   , src: '/Thumbnails//Mirrors/WCmirror.png' }             , 
{ tags:["foldup", "white"  ]            , src: '/Thumbnails//Doors/Doors_render_wht/White_Flip-up.png' } , 
{ tags:["bifold", "white"  ]            , src: '/Thumbnails//Doors/Doors_render_wht/White_Bi-Fold.png' } , 
{ tags:["sdoor" , "white"  ]            , src: '/Thumbnails//Doors/Doors_render_wht/White_Solid.png' }   , 
{ tags:["sdoor" , "blue" ]            , src: '/Thumbnails//Doors/Doors_render_blue/Blue_Solid.png' }   , 
{ tags:["foldup", "blue" ]            , src: '/Thumbnails//Doors/Doors_render_blue/Blue-Flip-up.png' } , 
{ tags:["bifold", "blue" ]            , src: '/Thumbnails//Doors/Doors_render_blue/Blue_Bi-Fold.png' } , 
{ tags:["bifold", "red"  ]            , src: '/Thumbnails//Doors/Doors_render_red/Red_Bi-Fold.png' }   , 
{ tags:["sdoor" , "red"  ]            , src: '/Thumbnails//Doors/Doors_render_red/Red_Solid.png' }     , 
{ tags:["foldup", "red"  ]            , src: '/Thumbnails//Doors/Doors_render_red/Red-Flip-up.png' }   , 
{ tags:["blue"]                       , src: '/Thumbnails//Colors/Blue.png' }                  , 
{ tags:["white"]                        , src: '/Thumbnails//Colors/White.png' }                 , 
{ tags:["red"]                        , src: '/Thumbnails//Colors/Red.png' }                   , 
{ tags:["yellow"]                     , src: '/Thumbnails//Colors/Yellow.png' }                , 
{ tags:["black"]                     , src: '/Thumbnails//Colors/Black.png' }                , 
{ tags:["green"]                      , src: '/Thumbnails//Colors/Green.png' }                 , 
{ tags:["dd"]                         , src: '/Thumbnails//Interior/DD_Drive.png' }            , 
{ tags:["rh"]                         , src: '/Thumbnails//Interior/RH_Drive.png' }            , 
{ tags:["lh"]                         , src: '/Thumbnails//Interior/LH_Drive.png' }            , 
{ tags:["flipd-left" , "flipd-right"] , src: '/Thumbnails//Interior/Flip-Up_Fold-Down.png' }           , 
{ tags:["sentry-left", "sentry-right"], src: '/Thumbnails//Interior/Sears_Sentry.png' }                , 
{ tags:["2way-left"  , "2way-right"]  , src: '/Thumbnails//Interior/2-Way_Adjustable.png' }            , 
{ tags:["asphalt_gray"]               , src: '/Thumbnails//Interior/Ashphalt_Gray_Cloth.png' } , 
{ tags:["vinyl"]                      , src: '/Thumbnails//Interior/Black_Viny.png' }          , 
]
}


