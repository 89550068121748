import { FC } from 'react';
import {  Layout } from 'antd';
import './App.css';
import {Content, Footer, Header} from 'antd/lib/layout/layout';
import TruckBuilder from './TruckBuilder';

const App: FC = () => (

  <div className="App">

    <Layout>
      <Header><span>Battle Motors - Truck Builder</span></Header>
      <Content>
        <TruckBuilder />
      </Content>
      <Footer></Footer>
    </Layout>


  </div>
);

export default App;
