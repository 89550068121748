import {Affix, Button, StepProps, Steps} from "antd";
import {useState} from "react";
import SummaryView from "./SummaryView";
import {Group, Step} from "./navigation";
import {TruckPart} from "./parts";
import {groupHasParts, stepHasParts } from "./utils";
import GroupView from "./GroupView";
import _ from "lodash";

interface Props {
  steps:Step[] 
  configuration: TruckPart[]
  selectedStep:Step
  selectedParts:TruckPart[]
  onChange:(s:Step)=>void
  onSelectPart:(g:Group,p:TruckPart)=>void 
}
const StepView = ( props:Props ) => {
  const { steps, configuration, selectedStep, onSelectPart, onChange } = props;

  const [showSummary, setShowSummary] = useState<boolean>(false);


  const SUMMARY_NDX = 4;
  const stepNdx = showSummary ? SUMMARY_NDX : steps.findIndex( (s) => s.id === selectedStep?.id );

  const progressSteps:StepProps[] = steps.map( (s) => {
    const disabled = !stepHasParts(s, configuration);
    return { title:s.title, disabled };
  })
  .concat( { title:"Summary", disabled: false });

  const handleBackBtn = () => {
    const ndx = stepNdx - 1;
    const step = steps.slice( 0, ndx ).reverse().find( s => stepHasParts( s, configuration ) ) || _.first(steps);

    onChange( step! ); //ignore undefined warning
  }

  const handleNextBtn = () => {
    const ndx = stepNdx + 1;
    const step = steps.slice( ndx ).find( s => stepHasParts( s, configuration) );

    if ( step ) {

      onChange( step );
    }
    else {
      setShowSummary( true );
    }
  }

  const handleClickStep = ( ndx:number ) => {
    if ( ndx === SUMMARY_NDX ) {
      setShowSummary( true );
      return;
    }

    const step = steps[ ndx ];

    //abort if step has no parts
    if ( !stepHasParts(step, configuration ) ) return;

    if ( step ) {
      onChange( step );
    }
    setShowSummary( false );
  }

  const selectedGroups = selectedStep.groups.filter( g => groupHasParts(g, configuration ) );
  const stepDescription = showSummary ? "Let's get to the hard work.  Give us some details and we'll send a quote." : selectedStep.description; 

  const nextLbl = ( stepNdx === ( steps.length - 1 ) ) ? "Finish" : "Next";
  return <>
    <Affix>
      <div style={{padding: "30px 0px 0px 0px", width: "100%", }} className="bm-stepper" >
        <Steps 
          labelPlacement="vertical" 
          items={progressSteps} 
          current={stepNdx} 
          onChange={handleClickStep} />

        <div className="bm-stepper-description" style={{padding: "20px 40px 20px 40px",display:"flex", justifyContent: "space-between" }} >

          <div style={{display:"flex"}}>
            <div style={{ textAlign: "left"}} >{stepDescription}</div>
          </div>

          <div style={{display:"flex", justifyContent: "flex-end"}}>
            { ( stepNdx > 0 && !showSummary ) && (
              <div>
                <Button type="primary" onClick={handleBackBtn} style={{marginLeft: "12px"}} > Back </Button>
              </div>
            )}
            { ( !showSummary && stepNdx < steps.length ) && (
              <div>
                <Button type="primary" style={{marginLeft: "12px"}} onClick={handleNextBtn}>{nextLbl} </Button>
              </div>
            )}
          </div>
        </div>

      </div>
    </Affix>
    {!showSummary && (
      <div style={{padding: "20px" }} >
        {selectedGroups.length === 0 && (
          <h3 style={{marginTop: "80px"}}>No parts to select.</h3>
        )}
        <div>
          {selectedGroups.map( (g, ndx) => (
            <div key={ndx} >
              <GroupView 
                group={g} 
                configuration={configuration} 
                onSelectPart={onSelectPart}  />
            </div>
          ))}
        </div>
      </div>
    )}
    {showSummary && (
      <SummaryView configuration={configuration} />
    )}
  </>
};

export default StepView;

