import {ReactElement, useState} from "react";
import {Affix, Image} from "antd";
import {getGroups, getSteps, Group, MISSING_IMAGE, Step} from "./navigation";
import IntroView from "./IntroView";
import StepView from "./StepView";
import {getPartImages, getTruckParts, TruckPart} from "./parts";
import {getTrucksImages} from "./trucks";
import {fillDefaults as buildConfiguration, getImgForTags, hasRequired, togglePart} from "./utils";
import ConflictModal, {Conflict} from "./ConflictModal";
import _ from "lodash";

const truckImages = getTrucksImages()
const partImages = getPartImages();
const bareParts = getTruckParts();
const truckPartsData = bareParts.map( p => ({ 
  ...p, 
  src: getImgForTags(partImages, p.tags ),
}) );
const groupsData:Group[] = getGroups().map( g => ({...g, parts: truckPartsData.filter( p => g.partIds.includes(p.id) ) }));
const stepsData:Step[] = getSteps().map( s => ({...s, groups: groupsData.filter(g => s.groupIds.includes( g.id ) ) } ) );


const TruckBuilder:React.FC = ():ReactElement => {

  const [ selectedParts, setSelectedParts ] = useState<TruckPart[]>();
  const [ selectedStep, setSelectedStep ] = useState<Step>();
  const [conflict, setConflict] = useState<Conflict>();

  const configuration = buildConfiguration( groupsData, selectedParts );
  const steps = stepsData

  const handleBegin = () => {
    const step = steps[0];
    setSelectedStep( step );
  }

  const handleChangeStep = (s:Step) => {
    setSelectedStep( s );
  }

  const handleSelectPart = (group:Group, part:TruckPart, force:boolean) => {

    const selected = togglePart( part, group.parts, selectedParts );

    const c = buildConfiguration( groupsData, selected );

    //verify selections against configuration
    const invalid = selected.filter( p => !hasRequired( p, c) );
    if ( !force && !!invalid.length ) {
      setConflict( {
        part, invalid,
        accept: () => { setConflict(undefined ); handleSelectPart( group, part, true ) },
        cancel: () => { setConflict( undefined )  }
      });
      return;
    }

    //remove conflicting parts
    const updated = selected.filter( p => hasRequired( p, c ) );
    setSelectedParts( updated );
  }

  const showIntro = !selectedStep;
  const showStepView = selectedStep;

  const stepTags = selectedStep?.tags || steps[0].tags

  const configurationTags = configuration?.reduce( (acc:string[], p) => acc.concat( p.tags ), [] ) || [];
  const truckImg = getImgForTags( truckImages.filter( i => !!_.intersection( i.tags, stepTags ).length ), 
                                 configurationTags
                                ) ||  MISSING_IMAGE;
  return <div style={{display: "flex"}}>
    <div style={{ flexShrink: "1"}}>
      <Affix>
        <Image
          src={truckImg}
          preview={false}
        />
      </Affix>
    </div>
    <div style={{ flexShrink: "15", flexGrow: "1"}}>
      {showIntro && (
        <IntroView title="Welcome to Battle Motors Truck Builder" 
          body={"Play around with different configurations till you find the exact truck for your needs."} 
          onBegin={handleBegin} />
      )}
      {showStepView && (
        <StepView 
          steps={steps} 
          configuration={configuration}
          selectedStep={selectedStep}
          selectedParts={selectedParts || []}
          onChange={handleChangeStep} 
          onSelectPart={ (g, p) => handleSelectPart( g, p, false ) } 
        />
      )}
      <ConflictModal conflict={conflict} />
    </div>
  </div>

};

export default TruckBuilder;

