import {Modal} from "antd"
import {ReactElement} from "react"
import {TruckPart} from "./parts"
import Tile from "./Tile"

export interface Conflict {
  part: TruckPart
  invalid: TruckPart[]
  accept: ()=>void
  cancel: ()=>void
}

interface Props {
  conflict:Conflict | undefined
}
const ConflictModal = (props:Props):ReactElement => {
  const { conflict } = props;

  const showConfirmPartSelection = !!conflict

  return <Modal
                open={showConfirmPartSelection}
                onOk={conflict?.accept}
                onCancel={conflict?.cancel}
              >
                <h3 style={{textAlign: "center" }}>Selecting <span style={{fontSize: "italic"}}>{conflict?.part.title}</span> will unselect the following parts:</h3>

                <div style={{display: "flex", gap: "20px", flexWrap: "wrap", justifyContent: "center"}}>
                  {conflict?.invalid.map( (p, ndx0) => {
                    const src = p.src;
                    const title = p.title;

                    return (<div
                      key={`part-${ndx0}`}
                      className="tile"
                    >
                      <Tile 
                        key={`tile-${ndx0}`}
                        src={src} title={title} />
                    </div>)

                  })}
                </div>
              </Modal>

}

export default ConflictModal;

