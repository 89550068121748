export interface TruckImage {
  id:string
  src:string
  tags: string[]
} 

export function getTrucksImages() {
  return [
    {
      "id": "truckImg-250",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Powertrain/LNT_EV_BorgWarner_400.png",
      "tags": [
        "lnt",
        "ev",
        "ev400",
        "borgwarner",
        "powertrain"
      ]
    },
    {
      "id": "truckImg-251",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Powertrain/LNT_EV_BorgWarner_240.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "borgwarner",
        "powertrain"
      ]
    },
    {
      "id": "truckImg-252",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/Blue/LNT_EV240_blue_Sdoor_4WAYmirror.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "blue",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-253",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/Blue/LNT_EV240_blue_FoldUp.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "blue",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-254",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/Blue/LNT_EV240_blue_Sdoor_WCmirror.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "blue",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-255",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/Blue/LNT_EV240_blue_BiFold.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "blue",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-256",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/Blue/LNT_EV240_blue_Sdoor_WCHmirror.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "blue",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-257",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/Red/LNT_EV240_red_Sdoor_4WAYmirror.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "red",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-258",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/Red/LNT_EV240_red_Sdoor_WCHmirror.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "red",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-259",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/Red/LNT_EV240_red_FoldUp.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "red",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-260",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/Red/LNT_EV240_red_BiFold.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "red",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-261",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/Red/LNT_EV240_red_Sdoor_WCmirror.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "red",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-262",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/White/LNT_EV240_wht_Sdoor_WCmirror.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "white",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-263",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/White/LNT_EV240_wht_BiFold.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "white",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-264",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/White/LNT_EV240_wht_Sdoor_WCHmirror.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "white",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-265",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/White/LNT_EV240_wht_FoldUp.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "white",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-266",
      "src": "/assets//BM - Lead Gen 3D/LNT/EV/Cab Colors/White/LNT_EV240_wht_Sdoor_4WAYmirror.png",
      "tags": [
        "lnt",
        "ev",
        "ev240",
        "white",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-267",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Powertrain/LNT_Powertrain_i9.png",
      "tags": [
        "lnt",
        "L9",
        "diesel",
        "powertrain"
      ]
    },
    {
      "id": "truckImg-268",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Powertrain/LNT_Powertrain_x12.png",
      "tags": [
        "lnt",
        "X12",
        "diesel",
        "powertrain"
      ]
    },
    {
      "id": "truckImg-269",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/Blue/LNT_Diesel_blue_BiFold.png",
      "tags": [
        "lnt",
        "diesel",
        "blue",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-270",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/Blue/LNT_Diesel_blue_FoldUp.png",
      "tags": [
        "lnt",
        "diesel",
        "blue",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-271",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/Blue/LNT_Diesel_blue_Sdoor_WCmirror.png",
      "tags": [
        "lnt",
        "diesel",
        "blue",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-272",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/Blue/LNT_Diesel_blue_Sdoor_4WAYmirror.png",
      "tags": [
        "lnt",
        "diesel",
        "blue",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-273",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/Blue/LNT_Diesel_blue_Sdoor_WCHmirror.png",
      "tags": [
        "lnt",
        "diesel",
        "blue",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-274",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/LNT_Diesel_Red_Sdoor_WCmirro.textClipping",
      "tags": [
        "lnt",
        "diesel",
        "red",
        "sdoor",
        "wcmirro",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-275",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/Red/LNT_Diesel_red_Sdoor_4WAYmirror.png",
      "tags": [
        "lnt",
        "diesel",
        "red",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-276",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/Red/LNT_Diesel_red_Sdoor_WCHmirror.png",
      "tags": [
        "lnt",
        "diesel",
        "red",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-277",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/Red/LNT_Diesel_red_BiFold.png",
      "tags": [
        "lnt",
        "diesel",
        "red",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-278",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/Red/LNT_Diesel_red_Sdoor_WCmirror.png",
      "tags": [
        "lnt",
        "diesel",
        "red",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-279",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/Red/LNT_Diesel_red_FoldUp.png",
      "tags": [
        "lnt",
        "diesel",
        "red",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-280",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/White/LNT_Diesel_wht_FoldUp.png",
      "tags": [
        "lnt",
        "diesel",
        "white",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-281",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/White/LNT_Diesel_wht_Sdoor_WCHmirror.png",
      "tags": [
        "lnt",
        "diesel",
        "white",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-282",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/White/LNT_Diesel_wht_Sdoor_WCmirror.png",
      "tags": [
        "lnt",
        "diesel",
        "white",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-283",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/White/LNT_Diesel_wht_Sdoor_4WAYmirror.png",
      "tags": [
        "lnt",
        "diesel",
        "white",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-284",
      "src": "/assets//BM - Lead Gen 3D/LNT/Diesel/Cab Colors/White/LNT_Diesel_wht_BiFold.png",
      "tags": [
        "lnt",
        "diesel",
        "white",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-285",
      "src": "/assets//BM - Lead Gen 3D/LNT/Model/LNT_NoEngine_Red_Sdoor_WCmirror.png",
      "tags": [
        "lnt",
        "noengine",
        "red",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-286",
      "src": "/assets//BM - Lead Gen 3D/LNT/Model/LNT_NoEngine_Wht_Sdoor_WCmirror.png",
      "tags": [
        "lnt",
        "noengine",
        "white",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-287",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_blk_sentry_2way.png",
      "tags": [
        "lnt",
        "rh",
        "black_vinyl",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-288",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_blk_flipD_flipD.png",
      "tags": [
        "lnt",
        "rh",
        "black_vinyl",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-289",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_gry_sentry_sentry.png",
      "tags": [
        "lnt",
        "rh",
        "asphalt_gray",
        "sentry-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-290",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_blk_2way_sentry.png",
      "tags": [
        "lnt",
        "rh",
        "black_vinyl",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-291",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_blk_sentry_flipD.png",
      "tags": [
        "lnt",
        "rh",
        "black_vinyl",
        "sentry-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-292",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_gry_flipD_2way.png",
      "tags": [
        "lnt",
        "rh",
        "asphalt_gray",
        "flipd-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-293",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_gry_2way_sentry.png",
      "tags": [
        "lnt",
        "rh",
        "asphalt_gray",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-294",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_blk_2way_flipD.png",
      "tags": [
        "lnt",
        "rh",
        "black_vinyl",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-295",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_gry_flipD_sentry.png",
      "tags": [
        "lnt",
        "rh",
        "asphalt_gray",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-296",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_gry_2way_flipD.png",
      "tags": [
        "lnt",
        "rh",
        "asphalt_gray",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-297",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_blk_flipD_2way.png",
      "tags": [
        "lnt",
        "rh",
        "black_vinyl",
        "flipd-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-298",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_blk_sentry_sentry.png",
      "tags": [
        "lnt",
        "rh",
        "black_vinyl",
        "sentry-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-299",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_gry_sentry_2way.png",
      "tags": [
        "lnt",
        "rh",
        "asphalt_gray",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-300",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_gry_flipD_flipD.png",
      "tags": [
        "lnt",
        "rh",
        "asphalt_gray",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-301",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_blk_flipD_sentry.png",
      "tags": [
        "lnt",
        "rh",
        "black_vinyl",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-302",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_blk_2way_2way.png",
      "tags": [
        "lnt",
        "rh",
        "black_vinyl",
        "2way-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-303",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_gry_2way_2way.png",
      "tags": [
        "lnt",
        "rh",
        "asphalt_gray",
        "2way-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-304",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/RH/LNT_RH_gry_sentry_flipD.png",
      "tags": [
        "lnt",
        "rh",
        "asphalt_gray",
        "sentry-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-305",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_blk_sentry_flipD.png",
      "tags": [
        "lnt",
        "dd",
        "black_vinyl",
        "sentry-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-306",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_gry_flipD_sentry.png",
      "tags": [
        "lnt",
        "dd",
        "asphalt_gray",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-307",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_gry_flipD_2way.png",
      "tags": [
        "lnt",
        "dd",
        "asphalt_gray",
        "flipd-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-308",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_gry_flipD_flipD.png",
      "tags": [
        "lnt",
        "dd",
        "asphalt_gray",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-309",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_blk_flipD_sentry.png",
      "tags": [
        "lnt",
        "dd",
        "black_vinyl",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-310",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_gry_sentry_2way.png",
      "tags": [
        "lnt",
        "dd",
        "asphalt_gray",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-311",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_blk_2way_flipD.png",
      "tags": [
        "lnt",
        "dd",
        "black_vinyl",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-312",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_blk_2way_2way.png",
      "tags": [
        "lnt",
        "dd",
        "black_vinyl",
        "2way-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-313",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_gry_sentry_sentry.png",
      "tags": [
        "lnt",
        "dd",
        "asphalt_gray",
        "sentry-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-314",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_gry_2way_sentry.png",
      "tags": [
        "lnt",
        "dd",
        "asphalt_gray",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-315",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_gry_2way_flipD.png",
      "tags": [
        "lnt",
        "dd",
        "asphalt_gray",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-316",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_blk_flipD_2way.png",
      "tags": [
        "lnt",
        "dd",
        "black_vinyl",
        "flipd-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-317",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_gry_2way_2way.png",
      "tags": [
        "lnt",
        "dd",
        "asphalt_gray",
        "2way-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-318",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_blk_sentry_sentry.png",
      "tags": [
        "lnt",
        "dd",
        "black_vinyl",
        "sentry-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-319",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_gry_sentry_flipD.png",
      "tags": [
        "lnt",
        "dd",
        "asphalt_gray",
        "sentry-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-320",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_blk_2way_sentry.png",
      "tags": [
        "lnt",
        "dd",
        "black_vinyl",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-321",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_blk_flipD_flipD.png",
      "tags": [
        "lnt",
        "dd",
        "black_vinyl",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-322",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/DD/LNT_DD_blk_sentry_2way.png",
      "tags": [
        "lnt",
        "dd",
        "black_vinyl",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-323",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_blk_2way_sentry.png",
      "tags": [
        "lnt",
        "lh",
        "black_vinyl",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-324",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_gry_2way_flipD.png",
      "tags": [
        "lnt",
        "lh",
        "asphalt_gray",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-325",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_blk_flipD_2way.png",
      "tags": [
        "lnt",
        "lh",
        "black_vinyl",
        "flipd-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-326",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_gry_sentry_flipD.png",
      "tags": [
        "lnt",
        "lh",
        "asphalt_gray",
        "sentry-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-327",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_gry_flipD_2way.png",
      "tags": [
        "lnt",
        "lh",
        "asphalt_gray",
        "flipd-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-328",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_blk_2way_2way.png",
      "tags": [
        "lnt",
        "lh",
        "black_vinyl",
        "2way-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-329",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_blk_sentry_2way.png",
      "tags": [
        "lnt",
        "lh",
        "black_vinyl",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-330",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_blk_flipD_flipD.png",
      "tags": [
        "lnt",
        "lh",
        "black_vinyl",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-331",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_blk_2way_flipD.png",
      "tags": [
        "lnt",
        "lh",
        "black_vinyl",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-332",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_gry_sentry_2way.png",
      "tags": [
        "lnt",
        "lh",
        "asphalt_gray",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-333",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_gry_flipD_flipD.png",
      "tags": [
        "lnt",
        "lh",
        "asphalt_gray",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-334",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_gry_2way_2way.png",
      "tags": [
        "lnt",
        "lh",
        "asphalt_gray",
        "2way-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-335",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_gry_2way_sentry.png",
      "tags": [
        "lnt",
        "lh",
        "asphalt_gray",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-336",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_blk_flipD_sentry.png",
      "tags": [
        "lnt",
        "lh",
        "black_vinyl",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-337",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_gry_flipD_sentry.png",
      "tags": [
        "lnt",
        "lh",
        "asphalt_gray",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-338",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_gry_sentry_sentry.png",
      "tags": [
        "lnt",
        "lh",
        "asphalt_gray",
        "sentry-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-339",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_blk_sentry_sentry.png",
      "tags": [
        "lnt",
        "lh",
        "black_vinyl",
        "sentry-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-340",
      "src": "/assets//BM - Lead Gen 3D/LNT/Interior/LH/LNT_LH_blk_sentry_flipD.png",
      "tags": [
        "lnt",
        "lh",
        "black_vinyl",
        "sentry-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-341",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_blk_flipD_2way.png",
      "tags": [
        "rh",
        "black_vinyl",
        "flipd-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-342",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_gry_2way_flipD.png",
      "tags": [
        "rh",
        "asphalt_gray",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-343",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_gry_flipD_sentry.png",
      "tags": [
        "rh",
        "asphalt_gray",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-344",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_blk_2way_flipD.png",
      "tags": [
        "rh",
        "black_vinyl",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-345",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_blk_2way_2way.png",
      "tags": [
        "rh",
        "black_vinyl",
        "2way-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-346",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_gry_flipD_2way.png",
      "tags": [
        "rh",
        "asphalt_gray",
        "flipd-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-347",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_gry_2way_2way.png",
      "tags": [
        "rh",
        "asphalt_gray",
        "2way-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-348",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_blk_sentry_flipD.png",
      "tags": [
        "rh",
        "black_vinyl",
        "sentry-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-349",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_blk_2way_sentry.png",
      "tags": [
        "rh",
        "black_vinyl",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-350",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_gry_sentry_flipD.png",
      "tags": [
        "rh",
        "asphalt_gray",
        "sentry-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-351",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_blk_sentry_2way.png",
      "tags": [
        "rh",
        "black_vinyl",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-352",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_blk_flipD_flipD.png",
      "tags": [
        "rh",
        "black_vinyl",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-353",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_gry_sentry_sentry.png",
      "tags": [
        "rh",
        "asphalt_gray",
        "sentry-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-354",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_blk_sentry_sentry.png",
      "tags": [
        "rh",
        "black_vinyl",
        "sentry-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-355",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_blk_flipD_sentry.png",
      "tags": [
        "rh",
        "black_vinyl",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-356",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_gry_sentry_2way.png",
      "tags": [
        "rh",
        "asphalt_gray",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-357",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_gry_flipD_flipD.png",
      "tags": [
        "rh",
        "asphalt_gray",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-358",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/RH/RH_gry_2way_sentry.png",
      "tags": [
        "rh",
        "asphalt_gray",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-359",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_gry_2way_sentry.png",
      "tags": [
        "dd",
        "asphalt_gray",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-360",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_gry_sentry_sentry.png",
      "tags": [
        "dd",
        "asphalt_gray",
        "sentry-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-361",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_gry_flipD_sentry.png",
      "tags": [
        "dd",
        "asphalt_gray",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-362",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_gry_flipD_flipD.png",
      "tags": [
        "dd",
        "asphalt_gray",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-363",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_gry_sentry_2way.png",
      "tags": [
        "dd",
        "asphalt_gray",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-364",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_blk_2way_2way.png",
      "tags": [
        "dd",
        "black_vinyl",
        "2way-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-365",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_blk_flipD_flipD.png",
      "tags": [
        "dd",
        "black_vinyl",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-366",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_blk_sentry_2way.png",
      "tags": [
        "dd",
        "black_vinyl",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-367",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_gry_2way_2way.png",
      "tags": [
        "dd",
        "asphalt_gray",
        "2way-right",
        "2way-left",
        "interior"
      ]
    },
    {
      "id": "truckImg-368",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_blk_sentry_sentry.png",
      "tags": [
        "dd",
        "black_vinyl",
        "sentry-left",
        "sentry-left",
        "interior"
      ]
    },
    {
      "id": "truckImg-369",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_blk_2way_sentry.png",
      "tags": [
        "dd",
        "black_vinyl",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-370",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_blk_sentry_flipD.png",
      "tags": [
        "dd",
        "black_vinyl",
        "sentry-right",
        "flipd-left",
        "interior"
      ]
    },
    {
      "id": "truckImg-371",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_gry_sentry_flipD.png",
      "tags": [
        "dd",
        "asphalt_gray",
        "sentry-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-372",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_blk_flipD_2way.png",
      "tags": [
        "dd",
        "black_vinyl",
        "flipd-left",
        "2way-left",
        "interior"
      ]
    },
    {
      "id": "truckImg-373",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_gry_2Way_flipD.png",
      "tags": [
        "dd",
        "asphalt_gray",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-374",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_blk_flipD_sentry.png",
      "tags": [
        "dd",
        "black_vinyl",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-375",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_blk_2Way_flipD.png",
      "tags": [
        "dd",
        "black_vinyl",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-376",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/DD/DD_gry_flipD_2way.png",
      "tags": [
        "dd",
        "asphalt_gray",
        "flipd-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-377",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_gry_sentry_sentry.png",
      "tags": [
        "lh",
        "asphalt_gray",
        "sentry-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-378",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_blk_flipD_sentry.png",
      "tags": [
        "lh",
        "black_vinyl",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-379",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_blk_2way_flipD.png",
      "tags": [
        "lh",
        "black_vinyl",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-380",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_gry_flipD_2way.png",
      "tags": [
        "lh",
        "asphalt_gray",
        "flipd-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-381",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_gry_sentry_flipD.png",
      "tags": [
        "lh",
        "asphalt_gray",
        "sentry-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-382",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_blk_flipD_2way.png",
      "tags": [
        "lh",
        "black_vinyl",
        "flipd-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-383",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_gry_2way_flipD.png",
      "tags": [
        "lh",
        "asphalt_gray",
        "2way-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-384",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_blk_sentry_sentry.png",
      "tags": [
        "lh",
        "black_vinyl",
        "sentry-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-385",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_blk_sentry_flipD.png",
      "tags": [
        "lh",
        "black_vinyl",
        "sentry-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-386",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_blk_2way_2way.png",
      "tags": [
        "lh",
        "black_vinyl",
        "2way-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-387",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_blk_sentry_2way.png",
      "tags": [
        "lh",
        "black_vinyl",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-388",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_blk_flipD_flipD.png",
      "tags": [
        "lh",
        "black_vinyl",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-389",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_blk_2way_sentry.png",
      "tags": [
        "lh",
        "black_vinyl",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-390",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_gry_2way_2way.png",
      "tags": [
        "lh",
        "asphalt_gray",
        "2way-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-391",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_gry_2way_sentry.png",
      "tags": [
        "lh",
        "asphalt_gray",
        "2way-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-392",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_gry_flipD_sentry.png",
      "tags": [
        "lh",
        "asphalt_gray",
        "flipd-left",
        "sentry-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-393",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_gry_sentry_2way.png",
      "tags": [
        "lh",
        "asphalt_gray",
        "sentry-left",
        "2way-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-394",
      "src": "/assets//BM - Lead Gen 3D/LET/Cab Interior/LH/LH_gry_flipD_flipD.png",
      "tags": [
        "lh",
        "asphalt_gray",
        "flipd-left",
        "flipd-right",
        "interior"
      ]
    },
    {
      "id": "truckImg-395",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Powertrain/EV_borgWarner_400.png",
      "tags": [
        "let",
        "ev",
        "borgwarner",
        "ev400",
        "powertrain"
      ]
    },
    {
      "id": "truckImg-396",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Powertrain/EV_borgWarner_240.png",
      "tags": [
        "let",
        "ev",
        "borgwarner",
        "ev240",
        "powertrain"
      ]
    },
    {
      "id": "truckImg-397",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/Blue/LET_EV240_blue_FoldUp.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "blue",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-398",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/Blue/LET_EV240_blue_Sdoor_4WAYmirror.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "blue",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-399",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/Blue/LET_EV240_blue_Sdoor_WCHmirror.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "blue",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-400",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/Blue/LET_EV240_blue_BiFold.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "blue",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-401",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/Blue/LET_EV240_blue_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "blue",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-402",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/Red/LET_EV240_red_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "red",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-403",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/Red/LET_EV240_red_Sdoor_WCHmirror.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "red",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-404",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/Red/LET_EV240_red_Sdoor_4WAYmirror.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "red",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-405",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/Red/LET_EV240_red_BiFold.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "red",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-406",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/Red/LET_EV240_red_FoldUp.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "red",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-407",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/White/LET_EV240_wht_Sdoor_WCHmirror.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "white",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-408",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/White/LET_EV240_wht_FoldUp.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "white",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-409",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/White/LET_EV240_wht_Sdoor_4WAYmirror.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "white",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-410",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/White/LET_EV240_wht_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "white",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-411",
      "src": "/assets//BM - Lead Gen 3D/LET/EV/Cab Colors/White/LET_EV240_wht_BiFold.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "white",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-412",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Powertrain/LET_Powertrain_i9.png",
      "tags": [
        "let",
        "diesel",
        "L9",
        "powertrain"
      ]
    },
    {
      "id": "truckImg-413",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Powertrain/LET_Powertrain_x12.png",
      "tags": [
        "let",
        "diesel",
        "X12",
        "powertrain"
      ]
    },
    {
      "id": "truckImg-414",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Model/LET_NoEngine_Wht_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "noengine",
        "white",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-415",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Model/LET_EV240_Wht_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "ev",
        "ev240",
        "white",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-416",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/Blue/LET_Diesel_blue_Sdoor_4WAYmirror.png",
      "tags": [
        "let",
        "diesel",
        "blue",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-417",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/Blue/LET_Diesel_blue_BiFold.png",
      "tags": [
        "let",
        "diesel",
        "blue",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-418",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/Blue/LET_Diesel_blue_Sdoor_WCHmirror.png",
      "tags": [
        "let",
        "diesel",
        "blue",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-419",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/Blue/LET_Diesel_blue_FoldUp.png",
      "tags": [
        "let",
        "diesel",
        "blue",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-420",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/Blue/LET_Diesel_blue_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "diesel",
        "blue",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-421",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/Red/LET_Diesel_red_BiFold.png",
      "tags": [
        "let",
        "diesel",
        "red",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-422",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/Red/LET_Diesel_red_Sdoor_4WAYmirror.png",
      "tags": [
        "let",
        "diesel",
        "red",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-423",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/Red/LET_Diesel_red_Sdoor_WCHmirror.png",
      "tags": [
        "let",
        "diesel",
        "red",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-424",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/Red/LET_Diesel_red_FoldUp.png",
      "tags": [
        "let",
        "diesel",
        "red",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-425",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/Red/LET_Diesel_red_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "diesel",
        "red",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-426",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/White/LET_Diesel_wht_Sdoor_WCHmirror.png",
      "tags": [
        "let",
        "diesel",
        "white",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-427",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/White/LET_Diesel_wht_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "diesel",
        "white",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-428",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/White/LET_Diesel_wht_FoldUp.png",
      "tags": [
        "let",
        "diesel",
        "white",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-429",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/White/LET_Diesel_wht_Sdoor_4WAYmirror.png",
      "tags": [
        "let",
        "diesel",
        "white",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-430",
      "src": "/assets//BM - Lead Gen 3D/LET/Diesel/Cab Colors/White/LET_Diesel_wht_BiFold.png",
      "tags": [
        "let",
        "diesel",
        "white",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-431",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Model/LET_CNG_Wht_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "cng",
        "white",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-432",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/Blue/LET_CNG_blue_Sdoor_4WAYmirror.png",
      "tags": [
        "let",
        "cng",
        "blue",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-433",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/Blue/LET_CNG_blue_Sdoor_WCHmirror.png",
      "tags": [
        "let",
        "cng",
        "blue",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-434",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/Blue/LET_CNG_blue_BiFold.png",
      "tags": [
        "let",
        "cng",
        "blue",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-435",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/Blue/LET_CNG_blue_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "cng",
        "blue",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-436",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/Blue/LET_CNG_blue_FoldUp.png",
      "tags": [
        "let",
        "cng",
        "blue",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-437",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/Red/LET_CNG_red_BiFold.png",
      "tags": [
        "let",
        "cng",
        "red",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-438",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/Red/LET_CNG_red_FoldUp.png",
      "tags": [
        "let",
        "cng",
        "red",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-439",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/Red/LET_CNG_red_Sdoor_4WAYmirror.png",
      "tags": [
        "let",
        "cng",
        "red",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-440",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/Red/LET_CNG_red_Sdoor_WCHmirror.png",
      "tags": [
        "let",
        "cng",
        "red",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-441",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/Red/LET_CNG_red_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "cng",
        "red",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-442",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/White/LET_CNG_wht_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "cng",
        "white",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-443",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/White/LET_CNG_wht_FoldUp.png",
      "tags": [
        "let",
        "cng",
        "white",
        "foldup",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-444",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/White/LET_CNG_wht_Sdoor_4WAYmirror.png",
      "tags": [
        "let",
        "cng",
        "white",
        "sdoor",
        "4waymirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-445",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/White/LET_CNG_wht_Sdoor_WCHmirror.png",
      "tags": [
        "let",
        "cng",
        "white",
        "sdoor",
        "wchmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-446",
      "src": "/assets//BM - Lead Gen 3D/LET/CNG/Cab Colors/White/LET_CNG_wht_BiFold.png",
      "tags": [
        "let",
        "cng",
        "white",
        "bifold",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-447",
      "src": "/assets//BM - Lead Gen 3D/LET/Model/LET_NoEngine_Wht_Sdoor_WCmirror.png",
      "tags": [
        "let",
        "noengine",
        "white",
        "sdoor",
        "wcmirror",
        "exterior",  
      ]
    },
    {
      "id": "truckImg-448",
      "src": "/assets/Powertrain/CNG/CNG_L9.png",
      "tags": [
        "cng",
        "L9",
        "powertrain"
      ]
    },
    {
      "id": "truckImg-449",
      "src": "/assets/Powertrain/CNG/CNG_X12.png",
      "tags": [
        "cng",
        "X12",
        "powertrain"
      ]
    },
  ];
}                  
