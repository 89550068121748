import {Button} from "antd";

interface Props {
  title:string
  body:string
  onBegin:()=>void
}
const IntroView = ( props:Props ) => {
  const { title, body, onBegin } = props;

  return <>
    <div style={{ padding:"20px", margin: "auto 0", marginTop: "80px" }}
    >
      <h2>{title}</h2>

      <p>{body}</p>

      <Button type="primary" onClick={onBegin}>Begin</Button>

    </div>


  </>

};

export default IntroView;


