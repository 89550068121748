import {TruckPart} from "./parts";
import HubspotForm from 'react-hubspot-form'
import {useRef} from "react";


function setNativeValue(element:any, value:any) {
  if ( !element ) {
    return;
  }
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter?.call(element, value);
  } else {
    valueSetter?.call(element, value);
  }
}

interface Props {
  configuration: TruckPart[]
}
const SummaryView = ( props:Props ) => {
  const { configuration } = props;

  const myRef = useRef<any>(null);

  const csvParts = configuration.map(p => `"${p.title}"`).join(",");
  return <>

    <div style={{padding: "40px", display: "flex", gap: "40px" }}>
      <div style={{ margin: "0 auto", width: "80%", }} >
        <HubspotForm
          ref={myRef}
          portalId='22125820'
          formId='570bf24d-3909-46d0-b2d2-8f88b2af58b8'
          onSubmit={(form:any) => {
          }}
          onReady={(form:any) => {
            //this is a terrible hack but works for now
            setTimeout( () => {
              const el = document.querySelector("iframe")?.contentWindow?.document.getElementsByName("build_battlemotors_com")[0];
              console.log( "udpate dom", el );
              setNativeValue(el, csvParts);
            }, 300 )

          }}
          loading={<div>Loading...</div>}
        />
      </div>
    </div>

  </>

};

export default SummaryView;


